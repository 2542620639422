import React from 'react';
import {Viewer,UrlTemplateImageryProvider,Credit,KmlDataSource,HeadingPitchRange, JulianDate,Rectangle} from '../../node_modules/cesium';
import 'cesium/Source/Widgets/widgets.css';

import CesiumNavigation from "cesium-navigation-es6";

class CesiumContainer extends React.Component {
  componentDidMount() {
    const viewer = new Viewer('cesiumContainer', {
      imageryProvider: 
      new UrlTemplateImageryProvider({
        url: 'https://cyberjapandata.gsi.go.jp/xyz/seamlessphoto/{z}/{x}/{y}.jpg',
        // url: 'https://cyberjapandata.gsi.go.jp/xyz/pale/{z}/{x}/{y}.png',
  //      url: 'https://cyberjapandata.gsi.go.jp/xyz/seamlessphoto/',fileExtension: 'jpg',
        credit: new Credit('地理院タイル', '', 'https://maps.gsi.go.jp/development/ichiran.html')
      }),
      baseLayerPicker: false,
      timeline : true,
      animation : false,
      fullscreenButton: false,
      homeButton: false,
      vrButton: false,
      geocoder:false,
      sceneModePicker:false,
      navigationHelpButton:false,
    });

    var options = {
      camera: viewer.scene.camera,
      canvas: viewer.scene.canvas,
    };
    console.log(this.props.dir);

    var dataSourcePromise = viewer.dataSources.add(KmlDataSource.load(this.props.dir,options));
    // var dataSourcePromise = viewer.dataSources.add(KmlDataSource.load(this.props.dir,options));

    viewer.zoomTo(dataSourcePromise, new HeadingPitchRange(0, -1, 50000));
    viewer._cesiumWidget._creditContainer.style.display = "none";

    const year = this.props.year;
    var currentTime = new JulianDate.fromIso8601(year+"-07-11T16:00:00Z");

    viewer.clock.currentTime = currentTime;


/// Navigation /////
var navoptions = {};
navoptions.defaultResetView = Rectangle.fromDegrees(122, 20, 153, 45);
navoptions.enableCompass = true;
navoptions.enableZoomControls = true;
navoptions.enableDistanceLegend = true;
navoptions.enableCompassOuterRing = true;
CesiumNavigation(viewer, navoptions);
///// Navigation ///

  }
 


  render() {
    return <div><div id="cesiumContainer"  style={{width:"100%", height:"500px"}}></div></div>;
  }
}

export default CesiumContainer

